<template>
  <div class="tt-tail">
    <div v-if="!isMobile" class="ttmatching">
      <div class="tt-up">
        <el-card shadow="never" class="box-card">
          <template #header>
            <div class="card-header">
              <span>{{$t('message.search.tonnagename')}}</span>
            </div>
          </template>
          <el-form  label-width="100px" ref="tonnageForm" :model="tonnageForm" size="mini" :rules="rules">
            <el-form-item :label="$t('message.search.maxdwt')" prop="dwt">
              <el-input
                  :placeholder="$t('message.search.maxdwt')"
                  v-model.number="tonnageForm.dwt"
                  class="input-with-select"
                  style="width:80%"
                  type="number"
                  :min="0" oninput="if(value<0)value=0"
              >
                <template #append>
                  {{$t('message.T')}}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('message.search.opendate')" >
              <el-col :span="11">
                <el-form-item prop="openStartDate">
                  <el-date-picker type="date" :placeholder="$t('message.search.startdate')" v-model="tonnageForm.openStartDate" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="11">
                <el-form-item prop="openEndDate">
                  <el-date-picker :placeholder="$t('message.search.enddate')" v-model="tonnageForm.openEndDate" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item :label="$t('message.search.openarea')">
              <el-col :span="11">
                <el-form-item prop="open_seaarea">
                  <el-autocomplete v-model="tonnageForm.open_seaarea"
                                   :placeholder="$t('message.search.seaarea')"
                                   style="width: 100%;"
                                   :fetch-suggestions="querySearchOpenSea"
                                   :trigger-on-focus="false"
                                   @select="selectopensea"
                                   @input="opensea($event)"
                                   @change="changeopenSeaareaName"
                  >
                  </el-autocomplete>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="11">
                <el-form-item prop="open_port">
                  <el-autocomplete v-model="tonnageForm.open_port"
                                   :placeholder="$t('message.search.openport')"
                                   style="width: 100%;"
                                   :fetch-suggestions="querySearchOpenPort"
                                   :trigger-on-focus="true"
                                   @select="selectopenport"
                                   @input="openport($event)"
                                   @change="reselectport"
                  >
                  </el-autocomplete>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item :label= "$t('message.tct.age')" prop="built">
              <el-col :span="18">
                <el-input
                    v-model.number="tonnageForm.built"
                    style="width: 100%"
                    :placeholder="$t('message.please_input')"
                    type="number"
                    :min="0" @input="maxAge"
                >
                  <template #append>
                    {{$t('message.years')}}
                  </template>
                </el-input>
              </el-col>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card shadow="never" class="box-card">
          <template #header>
            <div class="card-header">
              <span>{{$t('message.tct.name')}}</span>
            </div>
          </template>
          <el-form  label-width="80px" ref="tctForm" :model="tctForm" size="mini" :rules="rules">
            <el-form-item :label="$t('message.search.dwt')">
              <el-col :span="11">
                <el-form-item prop="dwtMin">
                  <el-input v-model.number="tctForm.dwtMin" type='number' :placeholder="$t('message.search.min')" style="width: 100%;"  :min="0" oninput="if(value<0)value=0" />

                </el-form-item></el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="11">
                <el-form-item prop="dwtMax">
                  <el-input v-model.number="tctForm.dwtMax" type='number' :placeholder="$t('message.search.max')" style="width: 100%;"  :min="0" oninput="if(value<0)value=0" />
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item :label="$t('message.search.laycan')" prop="laycandate">
              <el-col :span="11">
                <el-form-item  prop="laycan_start">

                  <el-date-picker type="date" :placeholder="$t('message.search.startdate')" v-model="tctForm.laycan_start" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="11">
                <el-form-item  prop="laycan_end">
                  <el-date-picker :placeholder="$t('message.search.enddate')" v-model="tctForm.laycan_end" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item :label="$t('message.tct.delivery_area')">
              <el-col :span="11">
                <el-form-item  prop="delivery_seaarea">
                  <el-autocomplete v-model="tctForm.delivery_seaarea"
                                   :placeholder="$t('message.search.seaarea')"
                                   style="width: 100%;"
                                   :fetch-suggestions="querySearchDeliverySea"
                                   :trigger-on-focus="false"
                                   @select="selectdeliverysea"
                                   @input="deliverysea($event)"
                                   @change="changedeliveryareaName"
                  >
                  </el-autocomplete>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="11">
                <el-form-item  prop="delivery_port">
                  <el-autocomplete v-model="tctForm.delivery_port"
                                   :placeholder="$t('message.search.openport')"
                                   style="width: 100%;"
                                   :fetch-suggestions="querySearchDeliveryPort"
                                   :trigger-on-focus="true"
                                   @select="selectdeliveryport"
                                   @input="deliveryport($event)"
                                   @change="reselectdeliveryport"
                  >
                  </el-autocomplete>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item :label="$t('message.tct.max_age')" prop="years">
              <el-col :span="18">
                <el-select v-model="tctForm.years" :placeholder="$t('message.search.choose')">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-button type="info" plain>{{$t('message.years')}}</el-button>
              </el-col>
              <el-col :span="6">
                <div class="btn-box">
                  <el-button size="small" type="primary" @click="TonnageTcT">{{$t('message.tct.matching')}}</el-button>
                  <el-button size="small" @click="reset">{{$t('message.tct.reset')}}</el-button>
                </div>
              </el-col>
            </el-form-item>
          </el-form>
        </el-card>
        <!-- <div class="btn-box">
          <el-button size="small" type="primary" @click="onSubmit">匹配</el-button>
          <el-button size="small" >重置</el-button>
        </div> -->
      </div>
      <div class="tt-down">
        <div v-for="(item,i) of match_list" :key="i" class="vs-card-box">
          <vs-card :keyvalue="'tonnagetct'" :matchLeft="item.tonnageCardModel" :matchRight="item.tctCardModel" :collect="TonnageTcT"></vs-card>
        </div>
      </div>
      <div  class="list-footer">
        <el-pagination
            small
            :prev-text="$t('message.previous')"
            :next-text="$t('message.next')"
            layout="slot,prev, pager, next"
            background
            v-model:currentPage="currentPage1"
            :total="pagination.total"
            :page-size="pagination.pagesize"
            @current-change="currentChange"
        />
      </div>
    </div>
    <div v-if="isMobile" class="ttmatching-mobile">
      <div class="tc-left">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>船舶动态</span>
            </div>
          </template>
          <el-form  label-width="80px" :model="tonnageForm" size="small" >
            <el-form-item label="载重吨">
              <el-input
                  placeholder="请输入载重吨"
                  v-model.number="tonnageForm.dwt"
                  class="input-with-select"
                  style="width:80%"
                  type="number"
                  :min="0" oninput="if(value<0)value=0" >
                <template #prepend>
                  <el-select v-model="select" :placeholder="$t('message.search.choose')" style="width:80px">
                    <el-option label="MT" value="1"></el-option>
                    <el-option label="CBM" value="2"></el-option>
                    <el-option label="CBF" value="3"></el-option>
                  </el-select>
                </template>
              </el-input>>
            </el-form-item>
            <el-form-item label="空船时间">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="tonnageForm.open_start_date" style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="11">
                <el-date-picker placeholder="选择日期" v-model="tonnageForm.open_end_date" style="width: 100%;"></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="空船海域">
              <el-col :span="11">
                <el-autocomplete v-model="tonnageForm.open_seaarea" placeholder="空船海域" style="width: 100%;"></el-autocomplete>
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="11">
                <el-autocomplete v-model="tonnageForm.open_port" placeholder="空船港口" style="width: 100%;"></el-autocomplete>
              </el-col>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>租船信息</span>
            </div>
          </template>
          <el-form  label-width="90px" :model="tctForm" size="small">
            <el-form-item label="载重吨区间">
              <el-col :span="9">
                <el-autocomplete v-model="tctForm.dwtMin" placeholder="空船海域" style="width: 100%;"></el-autocomplete>
              </el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="9">
                <el-autocomplete v-model="tctForm.dwtMax" placeholder="空船港口" style="width: 100%;"></el-autocomplete>
              </el-col>
            </el-form-item>
            <el-form-item label="受载期">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="tctForm.laycanStart" style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="11">
                <el-date-picker placeholder="选择日期" v-model="tctForm.laycanEnd" style="width: 100%;"></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="交船海域">
              <el-col :span="11">
                <el-autocomplete v-model="tctForm.deliverySeaareaId" placeholder="交船海域" style="width: 100%;"></el-autocomplete>
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="11">
                <el-autocomplete v-model="tctForm.deliveryPortId" placeholder="交船港口" style="width: 100%;"></el-autocomplete>
              </el-col>
            </el-form-item>
            <el-form-item label="船龄">
              <el-select v-model="tctForm.years" placeholder="请选择"  style="width: 70%;">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <span id="ship_years">Years</span>
            </el-form-item>
          </el-form>
        </el-card>
        <div class="btn-box">
          <el-button size="small" type="primary" @click="onSubmit">匹配</el-button>
          <el-button size="small" >重置</el-button>
        </div>
      </div>
      <h2>匹配结果</h2>
      <div class="tc-right">
        <div v-for="(item,i) of match_list" :key="i" class="vs-card-box">
          <vs-card :matchLeft="item[0]" :matchRight="item[1]"></vs-card>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import VsCard from '../../components/vs-card/VsCard.vue'
import {matchTCT, selectcollTCT} from "../../api/tonnage";
import {getseaarea} from "../../api/associativeinput";
export default {
  inject:['reload'],
  components:{VsCard},
  data(){
    const validatedwtMax = (rule, value, callback) => {
      if (parseInt(this.tctForm.dwtMin)>parseInt(this.tctForm.dwtMax)||
          parseFloat(this.tctForm.dwtMin)>parseFloat(this.tctForm.dwtMax)){
        callback(new Error(window.vm.$t('message.maxmessage')))
      }
      else {
        callback()
      }
    };
    const validatedwtmin = (rule, value, callback) => {
      if(this.tctForm.dwtMax!==''){
        if (parseInt(this.tctForm.dwtMin)>parseInt(this.tctForm.dwtMax)||
            parseFloat(this.tctForm.dwtMin)>parseFloat(this.tctForm.dwtMax)){
          callback(new Error(window.vm.$t('message.minmessage')))
        }
        else {
          callback()
        }}
    };
    const validateopendate = (rule, value, callback) => {
      if(this.tonnageForm.openStartDate>this.tonnageForm.openEndDate){
        callback(new Error(window.vm.$t('message.enddatevalide')))
      }
      else{
        callback()
      }
    };
    const validateopenStart = (rule, value, callback) => {
      if (this.tonnageForm.openEndDate !=='' &&this.tonnageForm.openStartDate > this.tonnageForm.openEndDate) {
        callback(new Error(window.vm.$t('message.startdatevalide')))
      }
      else{
        callback()
      }
    };
    const validatelaycandate = (rule, value, callback) => {
      if(this.tctForm.laycan_start>this.tctForm.laycan_end){
        callback(new Error(window.vm.$t('message.enddatevalide')))
      }
      else{
        callback()
      }
    };
    const validatelaycanStart = (rule, value, callback) => {
      if (this.tctForm.laycan_end !=='' &&this.tctForm.laycan_start > this.tctForm.laycan_end) {
        callback(new Error(window.vm.$t('message.startdatevalide')))
      }
      else{
        callback()
      }
    };
    return {
      tonnageForm:{
        open_seaarea:'',
        open_port:'',
        dwt:'',
        openStartDate:'',
        openEndDate:'',
        languageCode:'',
        openPortId:'',
        openSeaareaId:'',
        built:''
      },
      tctForm:{
        delivery_seaarea:'',
        delivery_port:'',
        dwtMin:'',
        dwtMax:'',
        laycanStart:'',
        laycanEnd:'',
        maxAge:'',
        deliveryPortId:'',
        deliverySeaareaId:'',
      },
      pagination: {
        current: 0,
        pagesize: 4,
        total: 0
      },
      options: [{
        value: '5',
        label: '5'
      }, {
        value: '10',
        label: '10'
      }, {
        value: '15',
        label: '15'
      }, {
        value: '20',
        label: '20'
      }, {
        value: '25',
        label: '25'
      },
        {
          value: 30,
          label: '30'
        },
        {
          value: 35,
          label: '35'
        },
        {
          value: 40,
          label: '40'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 60,
          label: '60'
        },
        {
          value: 70,
          label: '70'
        },
        {
          value: 80,
          label: '80'
        },
        {
          value: 90,
          label: '90'
        },
        {
          value: 100,
          label: '100'
      }],
      match_list:[],
      rules:{
        dwtMax: [{ validator: validatedwtMax, type: 'number', trigger: ['blur','change']}],
        dwtMin: [{ validator: validatedwtmin, type: 'number', trigger: ['blur','change']}],
        openStartDate: [{ validator: validateopenStart, trigger: ['blur','change']}],
        openEndDate: [{ validator: validateopendate,trigger: ['blur','change'] }],
        laycan_start: [{ validator: validatelaycanStart, trigger: ['blur','change'] }],
        laycan_end: [{ validator: validatelaycandate, trigger: ['blur','change'] }],
      }
    }
  },
  computed:{
    ...mapGetters(['isMobile','portnamegroup','seanamegroup'])
  },
  methods:{
    maxAge(value){
      if(value<0){
        value=0}else if(value>50){
        this.tonnageForm.built=50,
            this.$message.warning(window.vm.$t('message.shipage'))}
    },
    TonnageTcT(){
      if(this.lang == 'en'){
        this.tonnageForm.languageCode = 2
      }else{
        this.tonnageForm.languageCode = 1
      }
      this.query=Object.assign(this.tonnageForm,this.tctForm)
      const params = {
        query:this.query,
        pagination: this.pagination
      }
      selectcollTCT(params).then(response =>{
        if(response.error_code == 200){
          this.match_list=response.datas
          // for(var i = 0;i<this.match_list.length;i++){
          //   this.match_list[i].tctCardModel.tag='tct'
          //   this.match_list[i].tonnageCardModel.tag='tonnage'
          //
          // }

          //console.log(this.match_list)
          this.pagination.total = response.pagination.total
        }
      })
    },
    //开放海域联想输入
    querySearchOpenSea(queryString, callback){
      this.$store.commit('metadata/querySearchSea',queryString)
      callback(this.seanamegroup)
    },
    //开放港口联想输入
    querySearchOpenPort(queryString, callback){
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.tonnageForm.openSeaareaId })
      callback(this.portnamegroup)
    },
    //交船海域联想输入
    querySearchDeliverySea(queryString, callback){
      this.$store.commit('metadata/querySearchSea',queryString)
      callback(this.seanamegroup)
    },
    //交船港口联想输入
    querySearchDeliveryPort(queryString, callback){
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.tctForm.deliverySeaareaId })
      callback(this.portnamegroup)
    },
    //联想输入select
    selectopensea(item){
      this.tonnageForm.openSeaareaId = item.id
    },
    //港口关联海域
    selectopenport(item){
      this.tonnageForm.openPortId = item.id
      getseaarea(item.seaAreaId).then(res=>{
        this.tonnageForm.openSeaareaId= res.data.id
        if (this.lang == 'en') {
          this.tonnageForm.open_seaarea = res.data.ename
        } else {
          this.tonnageForm.open_seaarea = res.data.cname
        }
      })
    },
    selectdeliverysea(item){
      this.tctForm.deliverySeaareaId = item.id
    },
    //港口关联海域
    selectdeliveryport(item){
      this.tctForm.deliveryPortId = item.id
      getseaarea(item.seaAreaId).then(res=>{
        this.tctForm.deliverySeaareaId= res.data.id
        if (this.lang == 'en') {
          this.tctForm.delivery_seaarea = res.data.ename
        } else {
          this.tctForm.delivery_seaarea = res.data.cname
        }
      })
    },
    opensea(e){
      if(e == null){
        this.tonnageForm.openSeaareaId = ''
      }
    },
    openport(e){
      if(e == null){
        this.tonnageForm.openPortId = ''
      }
    },
    deliverysea(e){
      if(e == null){
        this.tctForm.deliverySeaareaId = ''
      }
    },
    deliveryport(e){
      if(e == null){
        this.tctForm.deliveryPortId = ''
      }
    },
    //不点击联想输入也可以正常搜索
    reselectsea(){
      if(this.seanamegroup.length != 0){
        for(let i=0;i<this.seanamegroup.length;i++) {
          if (this.seanamegroup[i].value == this.tonnageForm.open_seaarea){
            this.selectopensea(this.seanamegroup[i])
            break
          }

        }
      }
    },
    reselectport(){
      if(this.portnamegroup.length != 0){
        for(let i=0;i<this.portnamegroup.length;i++) {
          if (this.portnamegroup[i].value == this.tonnageForm.open_port) {
            this.selectopenport(this.portnamegroup[i])
            break
          }
        }
      }
    },
    reselectdeliverysea(){
      if(this.seanamegroup.length != 0){
        for(let i=0;i<this.seanamegroup.length;i++) {
          if (this.seanamegroup[i].value == this.tctForm.delivery_seaarea) {
            this.selectdeliverysea(this.seanamegroup[i])
            break
          }
        }
      }
    },
    reselectdeliveryport(){
      if(this.portnamegroup.length != 0){
        for(let i=0;i<this.portnamegroup.length;i++) {
          if (this.portnamegroup[i].value == this.tctForm.delivery_port)  {
            this.selectdeliveryport(this.portnamegroup[i])
          }
        }
      }
    },
    //海域改变清空港口
    changeopenSeaareaName(value){
      if(value==''){
        this.tonnageForm.openSeaareaId=''
      }else {
        this.tonnageForm.openPortId = ''
        this.tonnageForm.openPortEname = ''
        this.tonnageForm.openPortCname = ''
        this.tonnageForm.open_port = ''
        this.reselectsea()
      }
    },
    changedeliveryareaName(value){
      if(value==''){
        this.tctForm.deliverySeaareaId=''
      }else {
        this.tctForm.deliveryPortId = ''
        this.tctForm.deliveryPortEname = ''
        this.tctForm.deliveryPortCname = ''
        this.tctForm.delivery_port = ''
        this.reselectdeliverysea()
      }
    },
    currentChange(n) {
      this.pagination.current = n
      this.TonnageTcT()
    },
    reset(){
      this.reload()
    }
  },
  mounted() {
    this.TonnageTcT()
  }
}
</script>

<style lang="scss">
.tt-tail{
  width:100%;
  height:100%;
  .ttmatching{
    width:100%;
    height:100%;
    //display: flex;
    .tt-up{
      width:100%;
      //height:40%;
      overflow-y: hidden;
      display: flex;
      .box-card{
        width:40%;
        height:100%;
        border:none;
        margin-right: 20px;
        //background-color:red;
        .el-card__header{
          padding:6px 20px;
          border-bottom: 2px solid #e4e7ed;
          //height:20%;
          .card-header{
            //display: flex;
            justify-content: space-between;
            align-items: center;
            //background: pink;
            height:25px;
            span{
              font-size: 16px;
            }
          }
        }
        .el-card__body{
          padding:10px 20px;
          //display: flex;
          height:80%;
          //background-color: blue;
        }
        .el-form{
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          //background-color: pink;
        }
        .el-form-item{
          margin-bottom: 8px;
          #ship_years{
            margin-left: 15px;
          }
          .reset-btn{
            margin-left: 35%;
          }
        }
      }
      .btn-box{
        margin-left: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
    .tt-down{
      width:100%;
      height:50%;
      margin: 1% 0 0 0;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      //background: sienna;
      .vs-card-box{
        width:45%;
        margin-right: 5%;
        margin-bottom: 20px;
        height:160px;
      }
    }
    .list-footer{
      position: relative;
      margin-top: 5px;
      width:95%;
      display: flex;
      justify-content: center;
      //background: cadetblue;
      #tiao{
        margin-right: 30px;
      }
    }
  }
  .ttmatching-mobile{
    width:100%;
    //height:100%;
    .tc-left{
      width:98%;
      //height:100%;
      margin-left: 1%;
      min-width: 375px;
      .box-card{
        margin-top:2%;
        .el-card__header{
          padding:6px 20px;
          .card-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            //background: pink;
            height:25px;
            span{
              font-size: 16px;
            }
          }
        }
        .el-form-item{
          margin-bottom: 10px;
          #ship_years{
            margin-left: 15px;
          }
        }
      }
      .btn-box{
        //background: seagreen;
        margin: 5% 25%;
        display: flex;
        justify-content: space-around;
      }
    }
    h2{
      display: flex;
      justify-content: center;
      margin-top: 5%;
    }
    .tc-right{
      width:98%;
      //height:100%;
      //margin:0 1%;
      //background: sienna;
      .vs-card-box{
        width:100%;
        min-width: 415px;
        //margin-right: 5%;
        margin-bottom: 15px;
        height:160px;
        //@warnbackground: pink;
      }
    }
  }
}
</style>
